@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  /* colors */
  --primary-100: #e2e0ff;
  --primary-200: #a5f3fc;
  --primary-300: #67e8f9;
  --primary-400: #22d3ee;
  --primary-500: #06b6d4;
  --primary-600: #0891b2;
  --primary-700: #0e7490;
  --primary-800: #155e75;
  --primary-900: #164e63;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
};

/* Style the Nav Bar with Underline */
.nav_link::after {
  content: '';
  height:4px;
  width:100%;
  background-color: orange;
  display: block;
  opacity: 0;
  transition: all 0.4s;
  pointer-events: none;
}

.nav_link:hover::after {
  opacity: 1;
}

.nav_link.active {
  text-decoration-thickness: 0.22em;
	text-underline-offset: 0.1em;
	text-underline-offset: 0.2em;
	font-weight: bold;
	text-decoration: underline orange 5px;
}

/* Job Title Blink Color Effect */
.title_blink {
  text-shadow: 0 0 10px orange;
  animation:blink 4s infinite;
}

@keyframes blink {
  50% {
    text-shadow: none;
  }
}

/* Subsection Border */
.subsection-title {
  border-bottom:6px solid orange;
  display: table-cell; /* restrict border width to text width */
  width:100px;
}


/* Add Google Fonts */
.App {
  text-align: center;
  font-family: 'Fira Mono', monospace;
  font-family: 'Mulish', sans-serif;
}

/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
